@keyframes marquee {
    0% { transform: translateX(0%); }
    100% { transform: translateX(-100%); }
}

.marquee-hover:hover > p {
    animation: marquee 8s linear infinite;
    width: 100%;
    white-space: no-wrap;
    overflow: visible;
}

input[type="range"].styled-slider {
    @apply h-[3px];
    appearance: none;
  }
  
  /*progress support*/
  input[type="range"].styled-slider.slider-progress {
    --range: calc(var(--max) - var(--min));
    --ratio: calc((var(--value) - var(--min)) / var(--range));
    --sx: calc(0.5 * 10px + var(--ratio) * (100% - 10px));
  }
  input[type="range"].styled-slider:focus {
    outline: none;
  }
  
  /*webkit*/
  input[type="range"].styled-slider::-webkit-slider-thumb {
    @apply w-2.5 h-2.5 shadow-none mt-[calc(3px_*_0.5_-_10px_*_0.5)] rounded-[10px] border-[none];
    -webkit-appearance: none;
    background: #ff0000;
  }
  input[type="range"].styled-slider::-webkit-slider-runnable-track {
    @apply h-[3px] shadow-none rounded-none border-[none];
    background: #00000026;
  }
  input[type="range"].styled-slider::-webkit-slider-thumb:hover {
    background: #ff0000;
  }
  input[type="range"].styled-slider.slider-progress::-webkit-slider-runnable-track {
    background: linear-gradient(#ffffff, #ffffff) 0 / var(--sx) 100% no-repeat,
      #00000026;
  }
  input[type="range"].styled-slider.slider-progress:hover::-webkit-slider-runnable-track {
    background: linear-gradient(#ff0000, #ff0000) 0 / var(--sx) 100% no-repeat,
      #00000026;
  }
  
  /*mozilla*/
  input[type="range"].styled-slider::-moz-range-thumb {
    @apply w-2.5 h-2.5 shadow-none rounded-[10px] border-[none];
    background: #ff0000;
  }
  input[type="range"].styled-slider::-moz-range-track {
    @apply h-[3px] shadow-none rounded-none border-[none];
    background: #00000026;
  }
  input[type="range"].styled-slider::-moz-range-thumb:hover {
    background: #ff0000;
  }
  input[type="range"].styled-slider.slider-progress::-moz-range-track {
    background: linear-gradient(#ffffff, #ffffff) 0 / var(--sx) 100% no-repeat,
      #00000026;
  }
  input[type="range"].styled-slider.slider-progress:hover::-moz-range-track {
    background: linear-gradient(#ff0000, #ff0000) 0 / var(--sx) 100% no-repeat,
      #00000026;
  }
  
  /*ms*/
  input[type="range"].styled-slider::-ms-fill-upper {
    border: transparent;
    background: transparent;
  }
  input[type="range"].styled-slider::-ms-fill-lower {
    border: transparent;
    background: transparent;
  }
  input[type="range"].styled-slider::-ms-thumb {
    @apply w-2.5 h-2.5 shadow-none box-border mt-0 rounded-[10px] border-[none];
    background: #ff0000;
  }
  input[type="range"].styled-slider::-ms-track {
    @apply h-[3px] shadow-none box-border rounded-none border-[none];
    background: #00000026;
  }
  input[type="range"].styled-slider::-ms-thumb:hover {
    background: #ff0000;
  }
  input[type="range"].styled-slider.slider-progress::-ms-fill-lower {
    @apply h-[3px] m-0 rounded-[0px_0_0_0px] border-r-0 border-[none];
    background: #ffffff;
  }
  input[type="range"].styled-slider.slider-progress:hover::-ms-fill-lower {
    background: #ff0000;
  }