.quest-guide-text-content {
    @apply body-small font-normal text-cool-grey-600;
    
    b {
      @apply body-small font-normal text-cool-grey-600
    }

    a {
      @apply underline hover:text-cool-grey-500
    }

    ol {
      @apply list-decimal pl-6 body-small font-normal text-cool-grey-600
    }

    ul {
      @apply list-disc pl-6 body-small font-normal text-cool-grey-600
    }

    h1, h2, h3 {
      @apply title-7 text-cool-grey-700;

      * {
        @apply title-7;
      }
    }

    :is(h1,h2,h3) + :is(p, ol, ul) {
      @apply pt-4
    }

    :is(p, ol, ul) + :is(h1, h2, h3) {
      @apply pt-6
    }

    :is(p, ol, ul) + :is(p, ol, ul) {
      @apply pt-3
    }

    .audio-player-gradient {
      background: linear-gradient(180deg, rgb(0 0 0 / 20%) 0%, rgb(0 0 0 / 30%) 100%), linear-gradient(0deg, rgb(0 0 0 / 20%) 0%, rgba(0 0 0 / 20%) 100%), #4E3AAC;
    }
  }