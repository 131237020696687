.career span {
  color: #4380ee !important;
  font-weight: 500;
}

.mindset span {
  color: #25a4a7 !important;
  font-weight: 500;
}

.intrapreneurship span {
  color: #f5c918 !important;
  font-weight: 500;
}

.health span {
  color: #159f65 !important;
  font-weight: 600;
}

.soul span {
  color: #FF931FBF !important;
  font-weight: 500;
}

.collaboration span {
  color: #7E68EA !important;
  font-weight: 500;
}
/* 
.career::after,
.health::after,
.intrapreneurship::after,
.mindset::after .soul::after .collaboration::after {
  background-color: #d03c2f !important;
}

.career:hover::before,
.health:hover::before,
.intrapreneurship:hover::before,
.mindset:hover::before .soul::before .collaboration::before {
  background-color: #d03c2f !important;
}

.career span,
.health span,
.intrapreneurship span,
.mindset span,
.soul span,
.collaboration span {
  padding-bottom: 4px;
} */

svg {
  pointer-events: none;
}